/* start sticky footer */

body,
html {
  height: 100%;
}

/**
We are using UI Library basic styles that set the background color to gray.
We need to override it to white to match new designs. Ideally, we modify UI Library
so that it allows to customise the background color of the page.
*/
body {
  background: white !important;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* end sticky footer */
